$('.partners__slider').slick({
    slidesToShow: 6,
    infinite: true,
    speed: 300,
    variableWidth: true,
    arrows: true,
    appendArrows: $('.partners-arrows'),
    nextArrow: `<button class="partners__arrow button"><svg width="27" height="8" viewBox="0 0 27 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.3536 4.35355C26.5488 4.15829 26.5488 3.84171 26.3536 3.64645L23.1716 0.464466C22.9763 0.269204 22.6597 0.269204 22.4645 0.464466C22.2692 0.659728 22.2692 0.976311 22.4645 1.17157L25.2929 4L22.4645 6.82843C22.2692 7.02369 22.2692 7.34027 22.4645 7.53553C22.6597 7.7308 22.9763 7.7308 23.1716 7.53553L26.3536 4.35355ZM0 4.5H26V3.5H0V4.5Z" fill="#134BF2"/>
                </svg></button>
                `,
    prevArrow: `<button class="partners__arrow button"><svg width="27" height="8" viewBox="0 0 27 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM27 3.5L1 3.5V4.5L27 4.5V3.5Z" fill="#134BF2"/>
                </svg></button>
                `,

});

$('.gallery__slider').slick({
    appendArrows: $('.gallery-arrows'),
    nextArrow: `<button class="announce__arrow button"><svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.3536 4.35355C33.5488 4.15829 33.5488 3.84171 33.3536 3.64645L30.1716 0.464466C29.9763 0.269204 29.6597 0.269204 29.4645 0.464466C29.2692 0.659728 29.2692 0.976311 29.4645 1.17157L32.2929 4L29.4645 6.82843C29.2692 7.02369 29.2692 7.34027 29.4645 7.53553C29.6597 7.7308 29.9763 7.7308 30.1716 7.53553L33.3536 4.35355ZM0 4.5H33V3.5H0V4.5Z" fill="white"/>
                </svg></button>`,
    prevArrow: `<button class="announce__arrow button"><svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 3.64645C0.451183 3.84171 0.451183 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM34 3.5L1 3.5V4.5L34 4.5V3.5Z" fill="white"/>
                </svg></button>`,
})

function sliderInit() {
    var $slider = $('.anounce__slider');
    $slider.each(function () {
        var $sliderParent = $(this).parent();
        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: false,
            appendArrows: $('.anounce-arrows'),
            nextArrow: `<button class="announce__arrow button"><svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.3536 4.35355C33.5488 4.15829 33.5488 3.84171 33.3536 3.64645L30.1716 0.464466C29.9763 0.269204 29.6597 0.269204 29.4645 0.464466C29.2692 0.659728 29.2692 0.976311 29.4645 1.17157L32.2929 4L29.4645 6.82843C29.2692 7.02369 29.2692 7.34027 29.4645 7.53553C29.6597 7.7308 29.9763 7.7308 30.1716 7.53553L33.3536 4.35355ZM0 4.5H33V3.5H0V4.5Z" fill="white"/>
                        </svg></button>`,
            prevArrow: `<button class="announce__arrow button"><svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.646446 3.64645C0.451183 3.84171 0.451183 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM34 3.5L1 3.5V4.5L34 4.5V3.5Z" fill="white"/>
                        </svg></button>`,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        adaptiveHeight: true
                    }
                }
            ]
        });


        $(this).on('afterChange', function (event, slick, currentSlide) {
            $sliderParent.find('.slider__counter .active').html(currentSlide + 1);
        });

        let sliderItemsNum = $(this).find('.slick-slide').not('.slick-cloned').length;
        $sliderParent.find('.slider__counter .total').html(sliderItemsNum);

    });

};

sliderInit();


const burger = document.querySelector('.burger')
const navMenu = document.querySelector('.nav')
const menu = document.querySelector('.menu')

burger.addEventListener('click', () => {
    navMenu.classList.toggle('active')
    menu.classList.toggle('active')
})


$(window).bind('scroll', function () {
    if ($(window).scrollTop() > 100) {
        $('.menu').addClass('fixed');
        $('.hero').addClass('active');
    } else {
        $('.menu').removeClass('fixed');
        $('.hero').removeClass('active');
    }
});


const menuItem = document.querySelectorAll('.nav__li');

menuItem.forEach(item => {
    let subMenu = item.querySelector('.nav__submenu')
    item.addEventListener('click', (e) => {
        e.preventDefault()
        // subMenu.classList.toggle('active')
    })
    item.addEventListener('mouseover', (e) => {
        e.preventDefault()
        subMenu.classList.add('active')
    })

    item.addEventListener('mouseout', (e) => {
        e.preventDefault()
        subMenu.classList.remove('active')
    })
})

const companyMenu = document.querySelector('.company__info')
const companyDropdown = document.querySelector('.company__dropdown')
const companyDropdownClose = document.querySelector('.company__dropdown_close')
const searchPanel = document.querySelector('.search-panel')
const searchButton = document.querySelector('.search__button')
const headerHeight = document.querySelector('.header').clientHeight
const menuBar = document.querySelector('.menu')


companyMenu.addEventListener('click', (e) => {
    e.preventDefault()
    companyDropdown.classList.toggle('active')
})

companyDropdownClose.addEventListener('click', () => {
    companyDropdown.classList.remove('active')
})


menuBar.style.top = headerHeight + 'px'
console.log(headerHeight + 'px')

searchButton.addEventListener('click', () => {

    if (searchPanel.classList.contains('active')) {
        searchPanel.classList.remove('active')
        searchPanel.style.top = '-156px'
    } else  {
        searchPanel.classList.add('active')
        searchPanel.style.top = headerHeight + 56 + 'px'
    }



})